import React, {useState} from 'react';
import axios from 'axios';
import {Canvas} from 'react-three-fiber';
import {useLoader} from "@react-three/fiber";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Button, Spinner} from "@material-tailwind/react";
import {Suspense} from "react";
import {MeshPhongMaterial} from "three";
import {OrbitControls} from "@react-three/drei";


const Scene = ({responseUrl}) => {
    const obj = useLoader(OBJLoader, responseUrl);
    // Create a new toon material
    const material = new MeshPhongMaterial({
        color: 0xfffff0, // Base color
    });
    return (
        <>
            {/* Ambient light */}
            <ambientLight intensity={2}/>

            {/* Directional light for consistent shading */}
            <directionalLight position={[5, 5, 5]} intensity={1}/>

            {/* Spot light for focused illumination */}
            <spotLight position={[0, 20, 10]} angle={0.3} penumbra={0.2} intensity={1.5}/>

            {/* Apply the material directly to the loaded OBJ model */}
            <mesh material={material} geometry={obj.children[0].geometry}/>
        </>
    );
};


const ImageUploadComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [responseUrl, setResponseUrl] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Set preview URL for the selected image
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }

        setSelectedFile(file);
    };

    const handleUpload = async () => {
        try {
            if (!selectedFile) {
                alert('Please choose a file before uploading.');
                return;
            }

            setLoading(true);

            const formData = new FormData();
            formData.append('image', selectedFile);

            const response = await axios.post(
                'https://ear-api.sellioly.com/api/reconstruct_ear',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setResponseUrl(response.data.url);


            console.log('Response from server:', response.data);
        } catch (error) {
            console.error('Error uploading image:', error.message);
            alert('Error uploading image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="bg-white rounded-lg shadow-md p-8">
                {
                    responseUrl ?
                        (
                            <>
                                <div className="w-[600px] h-[600px]">

                                    <Canvas flat linear>
                                        <Suspense fallback={null}>
                                            <Scene responseUrl={responseUrl}/>
                                            <OrbitControls/>
                                        </Suspense>
                                    </Canvas>
                                </div>
                                <Button
                                    variant="filled"
                                    color="black"
                                    onClick={() => {
                                        setResponseUrl("");
                                        setPreviewUrl(null);
                                        setSelectedFile(null)
                                    }}
                                    disabled={loading}
                                    className="py-2 px-4 text-base font-medium rounded-md w-full"
                                >
                                    Reset
                                </Button>
                            </>
                        )
                        :
                        (
                            <div>
                                <h2 className="text-2xl font-bold mb-6">Upload Image</h2>

                                <div className="flex items-center mb-4">
                                    <input type="file" onChange={handleFileChange}
                                           className="w-full py-2 px-4 text-gray-700 border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"/>
                                </div>

                                {/* Display image preview */}
                                {previewUrl && (
                                    <img src={previewUrl} alt="Preview" className="w-[150px] mx-auto rounded-md mb-4"/>
                                )}


                                <div className="flex w-full items-center justify-center">
                                    {loading ? <Spinner/> : <Button
                                        variant="filled"
                                        color="black"
                                        onClick={handleUpload}
                                        disabled={loading}
                                        className="py-2 px-4 text-base font-medium rounded-md w-full"
                                    >
                                        Upload Image
                                    </Button>}
                                </div>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default ImageUploadComponent;
